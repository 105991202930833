import React, { useState } from 'react';
import axios from 'axios';

const DataDeletion = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleDeleteAccount = async () => {
        if (!email || !password) {
            setMessage('Please fill out all fields.');
            return;
        }

        try {
            setLoading(true);
            setMessage('');

            const response = await axios.post('https://dipps.onrender.com/auth/deleteAccountByAdmin', {
                email,
                password
            });

            if (response.data.success) {
                setMessage('Your account has been successfully deleted.');
                setEmail('');
                setPassword('');
            } else {
                setMessage(response.data.message || 'Something went wrong. Please try again.');
            }
        } catch (error) {
            setMessage(
                error.response?.data?.message || 'Failed to delete the account. Please try again later.'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='max-w-3xl px-3 py-6 mx-auto text-xl'>
            <div>
                <h2 className='text-3xl font-bold mb-4'>Deleting your Dipss account</h2>
                <p>If you’ve decided to leave Dipss, you can permanently delete your account. Once deleted, your profile, matches, and messages will be permanently removed and cannot be restored.</p>
            </div>
            <div className='mt-4 space-y-4'>
                <div>
                    <input
                        type="text"
                        className='border focus-visible:outline-gray-600 rounded-md px-4 py-2 md:w-1/2 w-full'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="password"
                        className='border focus-visible:outline-gray-600 outline-1 rounded-md px-4 py-2 md:w-1/2 w-full'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    className={`bg-black text-white px-4 py-2 rounded-lg ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleDeleteAccount}
                    disabled={loading}
                >
                    {loading ? 'Deleting...' : 'Delete Account'}
                </button>
                {message && <p className='mt-4 text-red-600'>{message}</p>}
            </div>
        </div>
    );
};

export default DataDeletion;
