import Header from './components/header/Header';
import './index.css';
import './assets/fonts/css/cabinet-grotesk.css';
import { useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import TermOfUse from './pages/TermOfUse';
import CommunityGuidelines from './pages/CommunityGuidelines';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import DataDeletion from './pages/dataDeletion/dataDeletion';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBr6ZE4keU3wQDlWozD8hqI3WHZXGsWMXM",
  authDomain: "dipss-902bb.firebaseapp.com",
  projectId: "dipss-902bb",
  storageBucket: "dipss-902bb.appspot.com",
  messagingSenderId: "448108646414",
  appId: "1:448108646414:web:9d215a21d9861a1585ec5f",
  measurementId: "G-7LDKZVMGFS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

function App() {

  const location = useLocation();
  const hideHeaderFooter = ['/privacy-policy', '/cookie-policy', '/terms-of-use', '/user-guidelines', '/delete-account'].includes(location.pathname);

  return (
    <div className="App">
      {!hideHeaderFooter && <Header />}
      {/* <Header /> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/cookie-policy' element={<CookiePolicy />} />
        <Route path='/terms-of-use' element={<TermOfUse />} />
        <Route path='/user-guidelines' element={<CommunityGuidelines />} />
        <Route path='/delete-account' element={<DataDeletion />} />
      </Routes>
      {/* <Footer /> */}
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
